<template>
  <div>
    Top
    <b-carousel
      ref="carousel"
      v-model="slide"
      :interval="0"
      background="#ababab"
      img-width="1024"
      img-height="780"
      no-warp
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide v-for="(slide, index) in slides" :key="index">
        <template #img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="780"
            :src="slide"
            @click="onClick"
            />
        </template>
      </b-carousel-slide>
    </b-carousel>
    End
  </div>
</template>

<script>
import { getLog } from '@/services/log';
let log = getLog('test-carousel');

export default {
  data() {
    return {
      slides: [
        "https://picsum.photos/1024/780/?image=55",
        "https://picsum.photos/1024/780/?image=56",
        "https://picsum.photos/1024/780/?image=52",
        "https://picsum.photos/1024/780/?image=51",
        "https://picsum.photos/1024/780/?image=50",
      ],
      slide: 0,
      moving: false,
    };
  },
  methods: {
    onSlideStart(slide) {
      log.log('onSlideStart', slide);
      this.moving = true;
    },
    onSlideEnd(slide) {
      log.log('onSlideEnd', slide);
      this.moving = false;
    },
    onClick(event) {
      log.log('click', event);
      if (this.moving) {
        log.log('still moving, ignoring click');
        return;
      }
      let ratio = event.offsetX / event.srcElement.width;
      let left = ratio < 0.5;
      log.log('click', left ? "left" : "right");
      if (left) {
        if (this.slide > 0)
          this.$refs.carousel.prev();
        else
          log.log('ignoring click');
      } else {
        if (this.slide < this.slides.length - 1)
          this.$refs.carousel.next();
        else
          log.log('ignoring click');
      }
    },
  }
};
</script>
